import './App.css'
import Home from './views/Home'

function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  )
}

export default App
