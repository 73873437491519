import React from 'react'
import { Layout } from 'antd'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'
import './index.scss'

export default function index() {
  return (
    <div className='home-container'>
      <Layout>
        <Header />
        <Content/>
        <Footer/>
      </Layout>
    </div>
  )
}
