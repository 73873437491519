import {useEffect, useState} from 'react'
import { Layout } from 'antd'
import API from "@/utils/api";
const { Footer } = Layout
export default function Index() {
  const [ICP, setICP] = useState('')

  useEffect(() => {
    API.getICP().then(res => {
      setICP(res.data)
    })
  }, [])


  return <Footer>
    <span>
      域名：pkp-app.top &nbsp;&nbsp;&nbsp;&nbsp;ICP备案号：
      <a href="https://beian.miit.gov.cn/" target="_blank">{ICP}</a>
    </span>
  </Footer>
}
