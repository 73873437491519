import React, {useEffect, useState} from 'react'
import {Layout, Card, Col, Row} from 'antd'
import API from '@/utils/api'

const {Content} = Layout

export default function Index() {
    const [pageConfig, setPageConfig] = useState([])
    useEffect(() => {
        API.pageConfig().then(res => {
            setPageConfig(res.data)
        })
    }, [])
    return (
        <Content>
            <Row gutter={16}>
                {pageConfig.map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} style={{marginBottom: '20px'}}>
                        <Card title={item.title} bordered={true}>
                            <a target="_blank" rel="noreferrer" href={item.url}>
                                跳转
                            </a>
                            <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Content>
    )
}
