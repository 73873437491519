import React from 'react'
import { Layout } from 'antd'
const { Header } = Layout

export default function index() {
  return (
    <Header>
      <div>首页导航</div>
    </Header>
  )
}
