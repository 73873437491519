import axios from 'axios'
const API = {
  pageConfig() {
    return axios.get('/pageConfig')
  },
  getICP(){
    return axios.get('/icp')
  }
}

export default API
