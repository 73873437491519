import axios from 'axios'

// axios.defaults.baseURL = process.env.NODE_ENV==='production'?'http://106.15.49.186:3000/p1':'http://localhost:3000/p1'
axios.defaults.baseURL = 'http://106.15.49.186:3000/p1'

axios.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
	function ({status,data}) {
		if (status === 200) {
			if (data.status !== 200) {
				return Promise.reject('服务器请求有问题')
			}
			return data
		}
		return Promise.reject('请求出错啦')
  },
  function (error) {
    return Promise.reject(error)
  }
)
